import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area   Start
export const configJSON = require("./config");

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start

    token: string,
    dropdownData: any[];                  
    data: any[],
    selectedService: boolean
    closeDrawer: boolean;
    selectedCardService: any;
    selectedServiceStatus: string;
    selectedCardIndex: any[];
    selectedButtonIndex: number,
    searchValue: string,
    sortType: string,
    multipleServiceSort: any[],
    currentPage: number,
    totalpages: number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ServiceProviderDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getFetchData = "";
  advancedsearchApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
            token: "",
            data: [],
            selectedService: false,
            closeDrawer: false,
            selectedCardService: [],
            selectedServiceStatus: "",
            selectedCardIndex: [],
            selectedButtonIndex: 0,
            searchValue: '',
            sortType: "",
            multipleServiceSort: [],
            dropdownData: [],
            currentPage: 1,
            totalpages: 3
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start

    this.setState({ data: configJSON.serviceProviders });
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  selectServiceHandler = (provider: any) => {
        const finalData = this.state.data.map((item, index) => {
            if (provider.index === index) {
                return {
                    ...item,
                    buttonIndex: provider.buttonIndex
                }
            } else {
                return item
            }
        }
        )
        this.setState({ data: finalData, selectedCardIndex: [...this.state.selectedCardIndex, provider.index], selectedCardService: [...this.state.selectedCardService, provider] })
  }

  closeDrawerHandler = () => {
    this.setState({ closeDrawer: !this.state.closeDrawer })
  }

    removeSelectedServiceHandler = (provider: any) => {
        const data = this.state.selectedCardService.filter((item: any) => item.buttonIndex !== provider.buttonIndex && item)
        const finalData = this.state.data.map((item, index) => {
            const { buttonIndex, ...rest } = item
            if (provider.index === index) {
                return {
                    ...rest
                }
            } else {
                return item
            }
        }
        )
        this.setState({ data: finalData, selectedCardService: data })

    }

    removeSelectedCardsFromServiceBlock = (index: number, cardItem: any) => {
        const finalData = this.state.selectedCardService.filter((item: any, i: number) => index !== i && item)
        const data = this.state.data.map((item, i) => {
            const { buttonIndex, ...rest } = item
            if (cardItem.index === i) {
                return {
                    ...rest
                }
            } else {
                return item
            }
        }
        )
        this.setState({ data: data })
        this.setState({ selectedCardService: finalData })
    }   
  // Customizable Area End
}